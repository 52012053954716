@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.layout-login {
    min-height: 100vh;

}

.layout-login .logo-login {
    padding: 1rem;
}

.layout-login .col-welcome {
    background-image: url("../../../public/img/bg.jpg");
    object-fit: contain;
    background-size: cover;

}

.layout-login .login-form-container {
    font-family: 'Poppins';
    height: 100%;
    display: flex;

}

.layout-login .login-form-container .login-form {
    max-width: 400px;
    width: 400px;
    font-family: 'Poppins';
    margin: auto;
    vertical-align: bottom;

}


.layout-login .login-form-container .login-form .input-email {
    margin-top: 3rem;
    font-family: 'Poppins';
}

.layout-login .link-password-recovery {
    position: absolute;
    font-family: 'Poppins';
    bottom: 0;
    right: 0;
    margin-bottom: 1rem;
    margin-right: 2rem;
    font-weight: 600;
    color: #000;
}

.layout-login .password-recovery-form {
    max-width: 550px;
    margin: 25% auto 0 auto;
    padding-right: 2rem;
    font-family: 'Poppins';
}

.layout-login .password-recovery-form .input-name {
    margin-top: 3rem;
    font-family: 'Poppins';
}

.btn-facebook,
.btn-facebook:hover,
.btn-facebook:focus {
    background-color: #4267b2;
    border: none;
}

@media (max-width:1131px) {
    .logo-login {
        width: 100%
    }

}

@media screen and (max-width:992px) {
    .link-password-recovery {
        display: block;
        position: relative !important;
        margin: auto;
        padding: 5px;
       
    }
}

@media screen and (max-width:425px) {

    .layout-login .login-form-container .login-form {

        width: auto!important;
    }
}
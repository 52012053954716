
.estatus-select {
    .ant-select-selector {
        padding: 0 !important;
    }
    .ant-select-selection-item {
        padding: 0 !important;
    }
}

.form-borderless {

    label {
        color: rgba(12, 13, 13, 0.6)!important;
    }

    .form-item-borderless {
        // border: 1px solid #fff;
        &:hover {
            border: 1px solid #dadada;
        }
    }

}

.divider-blue {
    background-color: #16B1C2;

}

/*
 * @component Listas para el despliege de registros generales
 * @description Quita el border inferior de los elementos de la lista
 */
 .component-list-modal {
    overflow-x: auto;
}

.component-list-modal .ant-list-header{
    text-transform: capitalize;

}
.component-list-modal .ant-list-header,
.component-list-modal .ant-list-items {
    min-width: 700px;
}

.component-list-modal .ant-list-header,
.component-list-modal .component-list-item {
    border: none !important;
}

.component-list-modal .ant-list-header .border-header-list {
    border-bottom: 1px solid var(--cyan-semi-dark);
}

.component-list-modal .component-list-item.component-list-item-red .ant-card-body {
    border: 1px solid #ff4d4f !important
}



/*
 * @component Listas para el despliege de registros generales
 * @description pone la paginacion integradada a la lista del lado izquierdo
 */

.component-list-modal .ant-list-pagination {
    text-align: left;
    margin-bottom: 5rem;
}

.component-list-modal.component-list-group .ant-list-pagination {
    text-align: left;
    margin-bottom: 2rem;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Quita padding superior del los elementos, para que no queden tan separados
 */

.component-list-modal .component-list-item {
    padding: 6px 0px !important;
}

.component-list-modal .component-list-item .ant-typography {
    line-height: 2rem;
}

.component-list-modal .component-list-item-red .ant-typography {
    line-height: 2rem;
    color: #ff4d4f;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Creae efecto de hover
 */

.component-list-item .hover:hover {
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.05);
}

/*
 * @component Listas para el despliege de registros generales
 * @description Los cards dento de la lista tomen todo el ancho 
 */

.component-list-modal .card-list {
    width: 100%;
    background: var(--card-back);
}

/*
 * @component Listas para el despliege de registros generales
 * @description Da mas espacion interior a los cards de las lista
 */

.component-list-modal .card-list .ant-card-body {
    padding: 13px;
    position: relative;
}

.component-list-modal.scroll .ant-list-header,
.component-list-modal.scroll .ant-list-items {
    min-width: 1000px;
}

.component-list-modal .ant-list-items .ant-typography {
    max-height: 32px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
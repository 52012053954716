$text: #194039;


.list-eventos {
    width: 100%;
    display: block;
}

.list-item {
    display: block;

    div:first-child {
        margin: auto;
    }
}

.carousel {
    .carousel-cover {
        max-height: 200px;
        min-height: 200px;
    }

    .ant-card-cover {
        background-color: rgba($color: #000, $alpha: 0.7);
        z-index: 1;
    }

    .image {
        display: block;
        width: 100%;
        max-height: 500px;
        min-height: 500px;
        object-fit: cover;
        z-index: 0;
        border-radius: 0 !important;
        filter: contrast(0.8);
    }

}

.card-evento {

    max-width: 350px;
    width: auto;
    min-width: 250px;
    border-radius: 10px;
    overflow: hidden;

    .carousel-cover {
        max-height: 200px;
        min-height: 200px;
    }

    .ant-card-cover {
        background-color: rgba($color: #000, $alpha: 0.7);
        z-index: 1;
    }

    .ant-card-body {
        margin: auto;
        max-width: 300px;
        width: auto;
        padding: 0.5rem;
        min-width: 220px;

        .ant-card-meta {
            padding-top: 10px;
            display: block;
            width: inherit !important;
        }

        .ant-card-meta-detail {
            margin: auto;
            margin: 4px;
            max-width: 300px;
            min-width: 220px;
            width: inherit !important;
        }
    }

    .image {
        display: block;
        width: 100%;
        max-height: 200px;
        min-height: 200px;
        object-fit: cover;
        z-index: 0;
        border-radius: 0 !important;
        filter: contrast(0.8);
    }


    .bg-black {
        background-color: rgba($color: black, $alpha: 0.8);
    }

    .tag {
        float: right;
        background-color: rgba($color: white, $alpha: 0.8);
        border-radius: 15px;
        max-width: 150px;
        max-height: 40px;
        line-height: 25px;
        border: 1px solid white;
        color: black;
        position: relative;
        top: -55px;
        left: -20px;
        z-index: 2;
    }

    .subtitle {
        font-size: 0.75em;
    }

    .divisor {
        background: #292D32;
    }

    .title {
        .ant-typography {
            margin: 0;
            padding: 0;
            width: 100%;
            font-size: 1.25em;
            text-overflow: ellipsis;
            max-height: 40px;

        }

        min-height: 40px;
        height: 40px;
        max-height: 40px;
        text-overflow: ellipsis;
    }

    .description {
        min-height: 100px;
    }

    .info {
        min-width: 100px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;

    }
}

#eventos-detalles {

    #about {
        .carousel-cover {
            max-height: 200px;
            min-height: 200px;
        }

        .ant-card-cover {
            background-color: rgba($color: #000, $alpha: 0.7);
            z-index: 1;
        }

        .ant-card-body{
        height: 347px;

        }
        .image {
            display: block;
            width: 100%;
            max-height: 200px;
            min-height: 200px;
            object-fit: cover;
            z-index: 0;
            border-radius: 0 !important;
            filter: contrast(0.8);
        }

        .evento-imagen {
            max-height: 300px;
            object-fit: cover;
        }

        .header {
            &-title {
                font-size: 28px;
                font-weight: 500;
            }
        }

    }

    #tabs {
        .ant-tabs-nav {
            margin: 0px;

            &::before {
                border-bottom: none;
            }
        }
    }

    #ticket-sales {
        background-color: #6D2D94;
        background-image: url("../../../../public/img/icTicket.png");
        background-repeat: no-repeat;
        background-position: right;

        .ant-typography {
            color: white;
        }

        .ant-card-body {
            position: relative;
            padding: 34px 20px 20px 20px;
        }

        .title {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 300;
        }

        .amount {
            font-size: 20px;
            font-weight: 600;
        }

        .text {
            font-size: 16px;
            font-weight: 600;
        }
    }


    .calendar-icon {
        color: #6D2D94;
        font-size: 1.25rem;
    }
}

.checkbox-images {
    position: absolute;
    top: -5px;
    right: 5px;
    z-index: 3;
    height: 15px;
    width: 15px;
    background: #fff !important;

    .ant-checkbox-wrapper-disabled {
        .ant-checkbox-inner {
            background: #1677ff !important;
        }
    }

}

.image-list-element {
    border: 1px dashed #dadada;
    border-radius: 5px;
}

/**
*@description Estilos para el componente de imagenes cargadas en el editor del evento
*@component Upload Imagen
*
*/
.card-picture {


    .ant-card-cover {
        padding: 5px;


        img {
            border-radius: 5px;
            width: 140px;
            height: 140px;
            object-fit: cover;
            background-color: #292D32;
        }
    }

    .options-overlay {
        width: 130px;
        height: 130px;
        position: absolute;
        top: 9px;
        z-index: 2;
        border-radius: 10px;
        background-color: #2c2c2c;
        left: 9px;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            opacity: 0.6;
            transition: opacity 5ms ease;

        }
    }

}

.fixed-buttons {
    float: right !important;
}


.fixed-form-button {
    margin-bottom: 10px;
    width: 400px;
    align-self: flex-end;
}

.green-button {
    background: #279027;

    &:hover {
        opacity: 0.8 !important;
        background: #279027 !important;
    }
}

.red-button {
    background: #E26200;

    &:hover {
        opacity: 0.8 !important;
        background: #E26200 !important;
    }
}

@media screen and (max-width: 768px) {
    .card-evento {
        width: auto;

        .ant-card-body {
            width: auto;

            .ant-card-meta {
                padding-top: 10px;
                display: block;
            }

            .ant-card-meta-detail {
                margin: auto;
                max-width: 300px;
                width: 260px !important;
            }
        }
    }

}

@media screen and (max-width: 320px) {
    .card-evento {
        width: auto;

        .ant-card-body {
            width: auto;

            .ant-card-meta {
                display: flex;
            }

            .ant-card-meta-detail {
                margin: auto;
                padding: 5px;
                width: auto;
            }

            .info {
                display: flex;
                flex-direction: column;
            }
        }
    }

}
/*
 * @component Card 
 * @description Estilos generales pra los cards del dasboard en su mayoria
 */
.card {

    border-radius: 2px;
    padding: 20px;
    height: 100%;

    .shadow {
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    }

    .grid {
        width: 100%;
        padding: 5px;
        border: none;
        border-color: #fff;
        box-shadow: none !important;
    }

    &-header {
        font-size: 1.25rem;
        font-weight: 100;
        padding: 0px;

        .date {
            font-size: 2.5rem;
            font-weight: 600;

            color: orange;



        }


        .date-text {
            color: #A3A3A3;
            font-size: 1rem;
        }
    }

    .dark {
        color: #464A53 !important;
    }

    .description {
        color: #A3A3A3;
        font-weight: 200;

    }

    .footer {
        color: #A3A3A3;
        font-weight: 600;

    }

    .statics-light {
        .ant-statistic-title {
            color: #FFFFFF;
            font-size: 0.8rem;
        }

        .ant-statistic-content {
            color: #FFFFFF !important;
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    .statics-dark {


        .ant-statistic-title {
            color: #000 !important;
            font-size: 0.940rem;
        }

        .ant-statistic-content {
            color: #000 !important;
            font-size: 2.188rem;
            font-weight: 600;
        }
    }

    .center-content {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;

    }



}

/*
 * @component Card 
 * @description Estilos para el card morado del dashboard
 */
.card-report {

    .title {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-size: 1.375rem;
    }

    .description {
        font-weight: 400;
        font-size: 1.125rem;
        color: #FFFFFF;
    }

    .amount {
        font-weight: 700;
        font-size: 2.188rem;
        color: #FFFFFF;

    }

    .grid {
        width: 70%;
    }
}

/*
 * @component Card 
 * @description Estilos para cards que abarcan un cuarto de la media vista 
 */
.card-md-4 {
    padding: 5px 10px;

    .ant-card-head {
        height: 10px;
        padding: 0 16px !important;
    }

    .ant-card-body {
        padding: 0 16px !important;

        .ant-statistic {
            height: 25px;
        }
    }
}

/*
 * @component Card 
 * @description Estilos el title del card
 */
.card-title {
    .ant-card-head * {
        font-size: 0.938rem;
        line-height: 54px;
        color: #FFFFFF !important;
        font-weight: 500;
        letter-spacing: 1px;
    }

    &-dark {
        color: #000 !important;
    }
}

/*
 * @component Card 
 * @description Estilos generales para el header del card personalizado 
 */
.card-title-custom {
    .ant-card-head * {
        font-weight: 400;
        font-size: 1.375rem;
        color: #464A53;
    }

}

/*
 * @component Card 
 * @description Estilos para que los cards abarquen todo el height disponible
 */
.card-h-full {
    height: 380px !important;

    .ant-card-body {
        height: 100% !important;
    }

    /*
    * @component Card 
    * @description Estilos para poner al final un elemento fijado para los contadores
    */
    .bottom-fixed {
        position: relative;
        top: 30px;

        .ant-statistic {
            .ant-statistic-title {
                color: #43E794;
                font-size: 1rem;
                font-weight: 500;
            }

            .ant-statistic-content {
                color: #464A53;
                font-weight: 700;
            }
        }
    }
}

/*
 * @component Card 
 * @description Estilos para el card de la vista de Layouts para los eventos
 */
.card-evento-prospecto {
    margin: 0 0 0 0 !important;
    min-width: auto;
    border-radius: 3px !important;
    position: relative;

    .ant-card-cover {
        padding: 0;
        top: 50%;
        bottom: 0;
    }

    .color {
        height: 10px;
        width: 100%;
        border-radius: 0 0 3px 3px;
        position: relative;
        bottom: 0;
    }
}

/*
 * @component Card 
 * @description Estilos para el card de la vista de Layouts para los clientes
 */
.card-prospecto-item {

    border-radius: 5px;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    margin: 8px 0;
    padding: 0px;

    &.selected {
        background-color: #bfbfbf
    }

    .ant-card-body {
        padding: 5px 5px;
    }

    .grid-4 {
        width: 25%;
    }

    .grid-2 {
        width: 50%;
    }

    .ant-badge {
        margin-left: 8px;
        margin-top: 4px;
    }

    .ant-badge-count {
        transform: translate(-100%, -50%);
    }

    .icon {
        position: inherit;
        left: -22%;
        bottom: -20px;
        border-radius: 50%;
        border: 0;
        background: #c2c2c2;
        padding: 5px;
        height: 18px;
        width: 18px;
    }

    .container-prospectos-card {
        position: relative;
        display: flex;

    }
}

/*
 * @component Custom 
 * @description Estilos para el card de la informacion del evento
 */
.card-tag {
    width: 100%;
    max-width: 300px;
    padding: 12px 24px;
    background-color: #F8F8F8;
    border-radius: 14px;

    &-data {
        .title {
            color: #759791;
            font-size: 14px;
            font-weight: 500px;
        }

        .info,
        .info * {
            color: #194039;
            font-size: 18px;
            font-weight: 500;
        }
    }
}

.h-200 {
    min-height: 200px;
    max-height: 200px;
}

.bg-pink {
    background: linear-gradient(208.63deg, #E36CD9 7.4%, #FE60AE 92.37%), #FFFFFF;
    box-shadow: 0px 3px 10px rgba(119, 119, 119, 0.1);
}

.bg-orange {
    background: linear-gradient(208.46deg, #F48665 8.05%, #F48665 91.95%, #FDA23F 91.95%), #FFFFFF;
    box-shadow: 0px 3px 10px rgba(119, 119, 119, 0.1);
}

.bg-green {
    background: linear-gradient(208.46deg, #23BDB8 8.05%, #43E794 91.95%), #FFFFFF;
    box-shadow: 0px 3px 10px rgba(119, 119, 119, 0.1);
}

.bg-white {
    background: #FFFFFF;

    .ant-statistic-title {
        color: black !important;
    }

}

.bg-dark {
    background: #222;
}

.event-card {
    background-color: #2175B017;
    margin: 1em;
    min-height: 420px;

    .ant-card-cover {
        overflow: hidden;
    }

    .ant-card-body {
        min-height: 165px;
    }

    .ant-card-actions {
        background: none !important;
        padding: 0 1rem;
    }

    .fecha {
        color: #B7B7B7;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 1.5em;
        text-transform: uppercase;
    }

    .vendidos {
        color: #B7B7B7;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 1em;
        text-transform: uppercase;
    }

    .nombre {
        color: white;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 1.5em;
        text-transform: uppercase;
    }

    .costo {
        color: #8DD859;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 1.2em;
        text-transform: uppercase;
    }


    .ver-evento {
        border-radius: 4px;
        height: 3em!important;
        background: #F5CD00 !important;
        border: none;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
        color: #000;
        float: right;
    }

    .h-50 {
        height: 50% !important;
    }

    .ant-card-cover {
        max-height: 200px;
    }

    img {
        width: 100%;
        max-height: 200px;
        /* min-height: 200px !important; */
        /* height: 200px !important; */
        // object-fit: contain;
        object-position: top;
        aspect-ratio: 1 / 0.7;
        overflow-y: hidden;
    }
}
.layout-landing {
  min-height: 100vh;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-color: #171921;



  //titulos de las secciones
  .sub-title {
    color: #7F2BE9;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }

  .title {
    color: white;
    font-size: 3em;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }


  .title-2 {
    font-size: 2.25em;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: "Poppins", sans-serif;
  }

  .text {
    color: white;
    font-size: 1.5em;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }

  .text-2 {
    font-size: 1em;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    color: white;
  }

  .description {
    color: white;
    font-size: 1.25em;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }

  .landing-list {
    width: 100%;
    list-style: decimal-leading-zero;

    li {
      margin-bottom: 0.5em;
    }

    .sub-list {
      text-transform: uppercase;
      color: #c2c2c2;
      font-size: .9em;
      letter-spacing: 2px;
    }

  }

  .divider {
    margin-top: 3em;
    margin-bottom: 3em;
    padding-top: 2em;
    padding-bottom: 2em;

    max-width: unset !important;
  }



  .ant-layout-content,
  .section {
    max-width: 1400px !important;
    align-items: center;
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
  }

  .section {
    align-self: center;
    justify-content: center;
  }


  .h-full {
    height: 100vh;
  }

  .image-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    min-height: 62vh;

    .btn-erp {
      padding-right: 20px;
      padding-left: 20px;
      width: 100%;
      max-width: 700px;
      min-height: 60px;
      border-radius: 3px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 19px;
      letter-spacing: 5px;
    }

    .logo-container {
      margin-top: 2rem;
    }

    .logo-container img {
      position: relative;
      right: 18px;
      height: 200px;
      width: auto;
    }
  }

  .image-section {
    width: 100%;
    height: 85vh;
    background-position: center;
    margin-top: -80px;
    z-index: 0;
    max-width: unset !important;
    padding: 0;

    .image-carousel {
      width: inherit;
      height: 84vh;
      object-fit: cover;
      overflow: hidden;
    }

    .video {
      width: 100%;
      height: 85vh;
      object-fit: cover;
    }

  }

  .image-section2 {
    background-image: url("../../../../public/img/bg2.png");
    background-repeat: no-repeat;
    width: 100%;
    min-height: 30vh;
    background-position: center;
    background-size: cover;
    max-width: unset !important;
    padding: unset;

    .foreground {
      background: #0b421184;
      width: 100%;
      min-height: inherit;
      padding-top: 3em;
      padding-bottom: 3em;
      display: flex;
      align-items: center;
    }
  }


  .btn-gradiente-morado {

    &.ant-btn-primary {
      border-radius: 3px;
      height: 3em;
      padding-left: 2em;
      padding-right: 2em;
      background: linear-gradient(97.46deg, #6D2D94 6.68%, #200767 102.35%);
      border: none;
      color: white
    }

  }

  .transition-section {
    min-height: 35vh;
    z-index: 1;
    margin-top: -35vh;
    background: linear-gradient(0deg, rgba(23, 25, 33, 1) 50%, rgba(23, 25, 33, 0) 100%);
    max-width: unset !important;
    font-family: 'Poppins', sans-serif;


    .title-img {
      width: 500px;
    }

    .description {
      color: white;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-top: 3em;
      max-width: 1200px
    }

    .description-evento {
      color: white;
      font-size: 18px;
      font-weight: 500;
      text-align: start;
      margin-top: 2em;
    }
  }

  .galeria {

    .title-2 {
      color: white;
      font-size: 3em;
      font-weight: 700;
      font-family: "Poppins", sans-serif;
    }

    .description {
      color: white;
      font-size: 1em;
      font-weight: 500;
      font-family: "Poppins", sans-serif;
    }

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 4 / 3;
      object-fit: contain;
    }
  }

  .video {
    width: 55%;
    height: auto;
    aspect-ratio: 2 / 1;
  }

  .ubicacion {
    .content {
      margin: auto;
      max-width: 850px;
    }

    .info {
      padding: 0 2em;
      background: linear-gradient(180deg, #6D2D94 0%, #471883 100%);
      display: flex;
      justify-items: start;



    }

    .map {
      height: 100%;
      width: 100%;
      object-fit: cover;
      background-size: cover;

    }



  }

  .info-maitai {
    color: white;
    font-size: 1.5em;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    text-align: center;

  }

  .logo-maitai {
    max-width: 450px;
  }


  .bg-gradient {
    background: linear-gradient(97.46deg, #6D2D94 6.68%, #200767 102.35%);

  }

  .contacto {
    padding: 20px;

    .contact-form {
      max-width: 90%;
      margin: auto;

      .ant-form-item-label label {
        font-weight: 500;
        letter-spacing: 1px;
      }

      .ant-form-item-extra {
        color: #dadada;
      }

    }


    .contact-card {
      max-width: 65vw;
      width: 600px;
      border-radius: 1em;
      background: linear-gradient(97.46deg, #6D2D94 6.68%, #200767 102.35%);


      label {
        color: white !important;
      }

      .ant-btn {
        border-radius: 3px;
        height: 3em;
        background: #202020;
        border: none;
        padding-left: 2em;
        padding-right: 2em;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
      }


    }
  }

  .cover {
    min-height: 100vh !important;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;

  }

  .filters {


    label {
      color: #B9B9B9;
    }

    .ant-input,
    .ant-input-outlined,
    .ant-select-selector,
    .ant-picker {
      border-color: transparent !important;
      background-color: #B9B9B980 !important;
      border-radius: 0;
      height: 4em;

      & ::placeholder,
      input::placeholder {
        color: #B9B9B9;
      }
    }

    .item-light * {

      ::placeholder {
        color: #B9B9B9 !important;

      }

      color: #B9B9B9 !important;
    }

    .selector {

      .ant-select-clear {
        margin-top: 0.48rem
      }

    }

    svg,
    .ant-select-selection-item {
      color: white !important;
    }

    .ant-select-arrow {
      margin-top: 0.5em;
    }

  }

  //end filters

  /*
  *PAYMENT
  */
  .resume {
    background-color: inherit;

    &-header {
      color: #F1F1F1;
      font-size: 1.5rem;
    }
  }

  .resume-list {
    color: #F1F1F1;

    .ant-list-items * {
      color: #F1F1F1 !important;
    }

    .item-costo {
      font-size: 1rem;
    }

    .total {
      font-size: 1.2rem;
      font-weight: 500;
    }

  }

  .bg-purple-deg {
    padding: 10px 20px;
    background: rgba(109, 45, 148, 0.16);
    border: 1px solid #6D2D94;
    border-radius: 8px;

  }

  .resume-list-footer-divider {
    background: #FFFFFF !important;
  }

  .result * {
    color: #F1F1F1;

    .link * {
      font-weight: 600;
      color: #6D2D94;
      font-style: oblique;
    }
  }

}

/*card gris con la informacion general del evento*/
.informacion {

  .card-info {
    background-color: #6D2D94;
    border-radius: 8px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 127%;
    color: #FFFFFF;
    text-align: center;
    padding: 0.7rem;
    width: 100%;
  }

  .card-general {

    background-color: #F4F4F433;
    border-radius: 8px !important;
    width: 100%;
    padding: 0.7rem 0;

    .titulo {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 19px;
      line-height: 27px;
      color: #B8BFD2;
    }

    .info {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
    }

    .info-divder {
      margin: 0.8rem 0;
      border-bottom: solid 1px #FFFFFF90;
    }


  }

  .markdown-body {
    color: #FFFFFF !important;

    * {
      color: #FFFFFF !important;
    }
  }


}

.info-mapa {

  color: white;
  display: inline-flex;

  .square {
    background: #B8BFD2;
    border-radius: 2px;
    width: 20px;
    height: 20px;

    &.yellow {
      background: #f1e82c;
    }

    &.gray {
      background: #808080;
    }

    &.red {
      background: #FA0D2F;
    }

    &.orange {
      background: #f7a32c;
    }

    &.green {
      background: #a9f454;
    }
  }

  .precios {
    display: inline-flex;
    font-style: italic;
  }
}




.scroll-background {
  * {
    padding: 0;
    margin: 0;
  }

  .box {
    margin: auto;
    max-width: 400px;
  }

  .bb-para {
    height: 60vh;
    width: 100%;
    color: white;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .scroll-divider {
    height: 7vh;
    background-color: #171921;
  }
}


@media (max-width: 767px) {
  .contacto {
    .contact-card {
      max-width: 90vw !important;
      width: 500px;
      border-radius: 1em;
    }
  }

  .title-img {
    max-width: 200px !important;
    filter: contrast(1);
  }

}

@media (max-width:452px) {
  .logo-maitai {
    max-width: 200px !important;
  }

  .description {
    text-align: center;
  }

  .card-text {
    margin: auto;
    max-width: 200px;
  }
}

@media screen and (min-width:768px) {
  .contacto {
    .contact-card {
      max-width: 50vw;
      width: 500px;
      border-radius: 1em;
    }
  }

}
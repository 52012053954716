.buscador-eventos {
    .ant-input-outlined {
        border-color: transparent !important;
        background-color: #B9B9B980 !important;
        border-radius: 2em 0px 0px 2em;
        height: 4em;
    }

    .ant-input-prefix {
        color: white !important;
        padding-right: 1em;
    }

    .ant-btn-primary {
        border-radius: 0px 2em 2em 0px;
        height: 4em;
        padding-left: 2em;
        padding-right: 2em;
        background: linear-gradient(97.46deg, #6D2D94 6.68%, #200767 102.35%);
        border: none;

    }

    .ant-input::placeholder {
        color: #B9B9B9 !important;
    }
}


@media screen and (max-width:468px) {

    .buscador-eventos {

        .form {
            max-width: 250px !important;
        }
    }

}

/*Estilos para el submenu flotante del crm*/
.sub-menu-float {
	position: absolute;
	background-color: white; 
	border: 1px solid #dfe1e4; 
	padding: 0px;
	z-index: 1000;

	ul {
		list-style: none; 
		padding: 0; 
		min-width: 100px;
	}

	li{
		padding: 4px 8px
	}

	li:hover{
		background-color: #dfe1e4
	}
}

.dragging{

	.card-prospecto-item{

		&.selected{
        	opacity: 0.7;
    	}
	}

}
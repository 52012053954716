.Form {
    animation: fade 200ms ease-out;
}

.FormGroup {
    margin: 0 0px 20px;
    padding: 0;
    /*border-style: none;*/
    background-color: #e5edf5;
    will-change: opacity, transform;
    /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),*/
    /*inset 0 1px 0 #829fff;*/
    border-radius: 2px;
    border: 1px solid #d9d9d9;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    /*border-top: 1px solid red;*/
}

.FormRow:first-child {
    border-top: none;
}

.FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #c4f0ff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}
.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

.FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #fff;
    background-color: red;
    animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
    color: #87bbfd;
}

.StripeElement--webkit-autofill {
    background: blue !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}


.card-stripe{
    max-width: 500px;
    margin: auto;
    border-radius: 25px !important;
}

.stripe-btn{
    border: none;
    background: #665bff;
    color: white;
    font-weight: bold;
}

.stripe-btn:hover{
    border: none;
    background: #968bff !important;
    color: white !important;
    
}
.card-faq{

    .title{
        color: #7F2BE9;
        font-size: 1.5em;
    }
    .icon{
        border: 1px solid #dadada;

        svg{
            color: #dadada;
        }
    }
}
/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen (logo) del header
 */
.logo {
    height: 40px;
    min-height: 40px;
}


/*
 * @component Header 
 * @description Cambia el tamaño del logo del header
 */
.logo img {
    height: 50px !important;
    width: 80%
}

/*
 * @component Header 
 * @description Cambia el poiner al hacer hover en la imagen del logo
 */

.logo:hover{
    cursor: pointer;
}

/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen para que se ajuste al sidebar
 * Si se mueve este estilo, se tiene que modificar tambien el del sider-width
 */
.header-logo {
    max-width: 250px;
    width: calc(100vw - (100vw * 0.78)) !important;
}

/*
* 
* CSS para los heders del sistema publicos y privados
*
*/

.header-admin {
    padding: 0 !important;
    height: unset !important;
    background: white !important;
    max-height: 80px !important;

    .content {
        max-height: inherit !important;

    }
}

.header-admin .logo-header {
    width: 100%;
    max-width: 250px;
    max-height: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #2B2929;

}

.header-admin .header-content {
    padding-top: 0.6rem;
}

.header-admin .ant-input-group-wrapper {
    max-width: 500px;
    margin-left: 1rem;
}

.notifications-list {
    width: 280px;
}

.notification-element {
    height: 70px !important;
}

.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-content-detail,
.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner .ant-comment-content .ant-comment-actions {
    margin: 0px;
    padding: 0px;
    line-height: 15px;
}

@media(max-width:835px) {
    .header-admin .logo-header {
        display: none;
    }
}

/*
 * @component Header -> InputSearch
 * @description Estilos de la barra de busqueda del header
 */
.header-search {
    display: flex;
    margin: 24px 10px;
    max-width: 437px;
}


/*
 * @component Header -> Menu 
 * @description Alinea los elementos del contenido del header despues del logo
 */
.header-content {
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    min-height: 100px;
}

/*
 * @component Header -> Menu 
 * @description Alinea los divs del contenedor de las opciones de usuario (notificaciones/logout)
 */
.header-options div {
    display: inline-block;
    margin: 5px 0;
    vertical-align: middle;
}


/*
 * @component Header -> Menu 
 * @description cambia el estilo del icono de notificaciones
 */
.header-options .icon-bell {
    color: #cccccc;
    font-size: 17pt;
    margin-bottom: -7PX;
}

/*
 * @component Header -> Menu 
 * @description cambia el estilo del bagde de notificaciones
 */
.header-options .ant-badge-count {
    background-color: #5e45d7;
}

/*
 * @component Header -> Menu 
 * @description Alinea los elementos del contenido del header despues del logo
 */
.header-content .dropdown-menu * {
    color: black
}

/*
 * @component Header -> InputSearch 
 * @description cambia el estilo input de busqueda del header 
 */

.input-global-search {
    flex: 1;
    max-width: 450px;
}



/*
 * @component Header   
 * @description Estilos del header para pantallas pequeñas
 */
@media (max-width: 768px) {
    .header-content {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        height: auto !important;
        padding-top: 1rem;
    }

    .header-content .header-search {
        order: 2;
        width: 100%;
        padding: 1rem 2.5rem 1rem 0;
    }

    .header-content .header-options {
        order: 1;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas medianas
 */
@media (max-width: 768px) {
    .header {
        display: contents !important;
        line-height: 1rem !important;
    }

    .header-logo {
        background: #2B2929;
    }

    .header .row-header-content {
        width: 100%;
        background: #2B2929;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas de medianas a largas
 */
@media (max-width: 1200px) and (min-width: 768px) {
    .header .header-logo .logo {
        min-width: auto;
        transition-duration: 1s !important;
    }
}
.transparent {
	background-color: transparent;
}

.navbar-public {
	display: flex;
	width: 100%;
	align-items: right;
	position: relative;
	z-index: 100;
	background: linear-gradient(0deg, rgba(23, 25, 33, 0.0) 10%, rgba(23, 25, 33, 0.8) 100%) !important;
	//transition: opacity 0.5s ease-out;
	height: 80px;

	&::before {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(23, 25, 33, 0.8) !important;
		z-index: -1;
		opacity: 0;
		transition: opacity 0.5s linear;
	}

	&.affixed::before {
		opacity: 1;
	}

	.logo {
		max-width: 250px;
		max-height: 100%;
		margin: auto;
		display: block;

	}

	.height-100 {
		height: 100%;
	}

	.ant-menu {
		font-weight: 600;
		font-size: 1.25rem;
		display: flex;
		align-items: center;
		align-content: stretch;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.ant-menu-item {
		color: #f1f1f1 !important;
		font-family: "Poppins", sans-serif;
		letter-spacing: 1px;
	}


	.btn-logged {
		border: solid 1px #f1f1f1;
		max-width: 150px;
		color: #f1f1f1 !important;
		font-family: "Poppins", sans-serif;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	.ant-menu-item:hover {
		background: transparent;
		color: #6D2D94 !important;
	}

	.ant-menu-item::after {
		border: 0 !important
	}

	.ant-menu-item-selected {
		background: transparent;
		font-weight: bold;
	}


	.menu-login {
		color: #f1f1f1;
	}
}


@media screen and (max-width:425px) {

	.logo {
		margin-left: 3rem !important;
	}

}
/*Modal que contiene el chat del cliente*/
.modal-cliente-chat{

    top: 25px;

    .ant-modal-content{
    border-radius: 20px;
        padding: 24px 0px 0px 24px;
    }

    .ant-popover-placement-top{

        .ant-popover-inner{
            padding: 0px
        }

    }

    .form-item-label-center{

        .ant-form-item-label{
            text-align: right;
        }

    }
}


/*contendor del chat total*/
.container-chat {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 20px;
    height: 700px;
    max-height: calc(100vh - 60px);
}

/*contenedor main del mensajes*/
.message-box {
    height: calc(100% - 200px);
    flex: 1;
    max-height: 100%
}

/*contenedor de archivos*/
.files-box {
    height: 80px;
    max-height: 200px;
    padding: 5px;
    overflow-y: auto;

    img {
        width: 80px;
        height: auto;
    }

    &:empty {
        display: none;
    }
}

/*contenedor de opciones del chat*/
.bottom-content {
    min-height: 50px;
    vertical-align: middle;
    display: flex;

    .form-comment {
        width: 100%;
    }

    .mentions-form-item {
        width: 100%;
    }


}

/*Contenedor de la lista mensajes de los chat*/
.lista-messages {
    height: 100%;
    max-height: inherit;
    overflow-y: scroll;
    padding: 0 5px;
    display: flex;
    flex-direction: column;


    /* puedes cambiar a 'repeat-x' o 'repeat-y' según tu preferencia */
    .message-inner {
        background: transparent;
        height: auto;


        .ant-comment-inner {
            padding: 5px 0px;

        }

        .ant-comment-content {
            padding: 10px;
            border: 1px solid #fff;
            border-radius: 10px;
            box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
            background: rgba(255, 255, 255, 0.4);

            .ant-comment-content-author {
                justify-content: space-between;


                .ant-comment-content-author-time {
                    color: #191919;
                }
            }
        }

        .ant-comment-actions {
            display: flex;
            flex-direction: row-reverse;
        }


    }

    .message-rrss {
        background: transparent;
        height: auto;
        width: 50%;


        .ant-comment-inner {
            padding: 5px 0px;

        }

        .ant-comment-content {
            padding: 10px;
            border: 1px solid #fff;
            border-radius: 10px;
            box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
            background: rgba(255, 255, 255, 0.4);

            .ant-comment-content-author-time {
                color: #191919;
            }

        }

        .user-writen,
        .user-message {
            color: inherit
        }

        .user-writen {
            font-weight: 700;
        }

        .user-writen-date {
            color: #696969 !important;
            font-size: 0.75rem;
        }


    }


    .message-auto {

        background: transparent;
        border: none !important;

        .ant-comment-inner {
            padding: 5px 0px;

        }

        .ant-comment-content {
            border: none !important;
            background: transparent;
            padding: 0;
            box-shadow: none;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;


            .ant-comment-content-author {
                justify-content: space-between;

                .ant-comment-content-author-time {
                    color: #191919;
                }
            }
        }

        .ant-comment-actions {
            display: flex;
            flex-direction: row-reverse;
        }

    }

    


    .message-content {
        position: relative;
        max-width: 50%;
        padding: 6px 8px;
        border: 1px solid #dcf7c5;
        border-radius: 10px;
        box-shadow: 30px 70px 120px rgba(27, 49, 66, 0.13);
        color: black;
        margin-bottom: 2px;
        background-color: #dcf7c5;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; 
        
        .message-span{
            font-size: 15px
        }

        &.right {
            float: right;
            margin-right: 8px; 
        }

        &.left {
            float: left;
            background: #fafafa;
        }

        .pending {
            color: #99610c !important;
            text-decoration: wavy !important;
            font-style: italic;

        }

        .unknown {
            color: #2e2d2d !important;
            font-weight: 300;
        }

        .received {
            font-weight: 500 !important;

        }

        .rejected {
            color: #910606 !important;

        }

        .date-span{
            position: absolute;
            bottom:0;
            right:5px;
            color: rgba(0,0,0,0.25);
        }

        .date-span-space{
            display: inline-block;
            width: 34px
        }

    }

    .message-error {
        font-style: italic;
    }

}

.chat-form-file {
    min-height: 50px;
    padding: 18px 0 12px 0;

    &-card {
        text-align: center;
        height: 112px;
        width: 100px;

        position: relative;

        transition: 0.2s opacity ease-out;

        .hover-content {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 6px;
            z-index: 100;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(3px);
            display: none;
            transition: 0.5 all ease-in-out;

            .button-delete-file {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%)
            }
        }

        &:hover .hover-content {
            display: block !important;
        }
    }

    &-image {
        width: 76px;
        height: 76px;
        // background: red;
        font-size: 40px;

        &-icon {
            padding-top: 10px;
        }
    }


}

.floating-button-small {
    position: fixed !important;
    display: flex;
    align-self: flex-end;
    bottom: 120px;
    right: 40px;
    height: 30px !important;
    width: 30px !important;
    border-radius: 50% !important;
    z-index: 10;

    svg {
        margin-top: 5px;
        height: 20px !important;
        width: 20px !important;
        color: #fff;
    }
}

/*contenedor de las imagenes del chat*/
.imagen-chat-contenedor {
    position: relative;
    display: inline-block;

    .image-chat {
        display: block;
        width: 80px;
    }

    .btn-delete-file {
        position: absolute;
        top: 10px;
        right: -10px;
        border: none;
        cursor: pointer;
    }
}

/*contenedor de la imagen con su boton de eliminar*/
.file-chat-contenedor {
    position: relative;
    display: inline-block;

    .btn-delete-file {
        position: absolute;
        top: -16px;
        right: -10px;
        border: none;
        cursor: pointer;
    }
}


.alert-custom {
    background: #E6F7FF;
    border: 1px solid#91d5ff;
    padding: 0.5rem 0.2rem;
}
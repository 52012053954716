@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Estilo para deshabilitar la selección de texto cuando CTRL está presionado */
* {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
}

// Estilo específico para los elementos de arrastre y soltar de react-beautiful-dnd
.react-beautiful-dnd-draggable,
.react-beautiful-dnd-drag-handle {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
#admin {
    min-height: 100vh;
}

.btn-delete {
    border: none;
    background: #FF547D;

    &.ant-popover-disabled-compatible-wrapper {
        background: none;
    }
}

.btn-edit {
    border: none;
    background: #456EFF;

    &.ant-popover-disabled-compatible-wrapper {
        background: none;
    }
}

.btn-view {
    border: none;
    background: #E88EFF;

    &.ant-popover-disabled-compatible-wrapper {
        background: none;
    }
}

.btn-borderless {
    border: none !important;
    box-shadow: none !important;
}

/**
*@Component Select
*@description Quita el borde al select
*/
.select-borderless *{
    border: none !important;
    box-shadow: none !important;
}

.ant-btn:not(.ant-btn-default) .anticon {
    color: white;
}

.point {
    height: 10px;
    width: 10px;
    background-color: #D12B00;
    border-radius: 15px;
    display: inline-block;
}

.hover-cursor {
    cursor: pointer;

    &:hover {
        border-bottom: 1px solid #5442A2 !important;
        transition-duration: 0.5s;
        transition: border-bottom-color 0.5s ease;
    }
}


.tag {
    min-width: 100px;
    width: 100%;
    max-width: 150px;
    border-radius: 10px;
    padding: 5px 8px;
    text-align: center;
    line-height: 18px;
    opacity: 0.7;
    border: 1px solid #fff;
    color:  #fff;
    font-weight: bolder !important;
    font-size: 0.75rem;
    display: block;
    align-items: center;
    word-break: normal;
}

/* select */
.tag-select {
    width: "100%";
    text-align: "center";
    padding-top: 6;
    padding-bottom: 6;
    font-size: 14;
    font-weight: "bold";
    cursor: "pointer";
    border-radius: 6px !important;

}

.scroll-x{
    overflow-x: scroll!important;
}

/**
*Tag colors
*
*
*/
.cancelado{
    background: #D12B00;
}
.rechazado{
    background: #E15534;
}
.registrado,
.pendiente{
    background: #F5CD00;
}
.parcial{
    background: #EC9720;
}
.pagado{
    background: #57EB57 ;
}

.btn-filter{
    background: #2B2929;
    color: #f1f1f1;
    border: none;
}
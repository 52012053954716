.footer-public {
    background-color: #171921;
    .ant-typography {
        color: #fff;
        font-size: 1em;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
    }

    .logo{
        display: block;
        margin: 0!important; 
    }
}
.page-header{
    padding: 16px 16px;
    margin-left: 0px !important;
    margin-right: 0px !important;

    &-title, &-subtitle{
        margin: 0!important;
        vertical-align: baseline;
        color: #2B2929 !important;
    }

    &-title{
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 32px !important;
    }
}